<template>
  <div class="about">
    <banner-hero :bannerHero="bannerHero" />
    <team />
  </div>
</template>
<script>
import BannerHero from '@/components/BannerHero';
import Team from '@/components/About/Team';

export default {
  components: { Team, BannerHero },
  data() {
    return {
      bannerHero: BannerHero.methods.Object(
        'Acerca de Hidra Brand Consulting',
        'hidra/logotipo.svg',
        'https://i.imgur.com/q4tZEQg.jpeg',
        ' ',
        'Somos un grupo de abogados de nueva generación, educados en un marco de derecho ' +
          'con respeto a nuestros clientes y con la firme idea de que siempre estará en primer lugar ' +
          'la justicia, sin sobrepasar los derechos de los demás.\n' +
          '<br />' +
          '<br />' +
          'Los profesionales que integra nuestra firma saben que el mayor activo que tenemos es su talento, ' +
          'mismo que está basado en el conocimiento de las áreas profesionales y servicios que ofrecemos. \n' +
          '<br />' +
          '<br />' +
          'En ese sentido nuestras acciones se ubican siempre en un ámbito de certeza jurídica y máxima ' +
          'seguridad en los servicios que prestamos.\n'
      ),
    };
  },
};
</script>
