<template>
  <!-- Start Team Member -->
  <section class="container py-5 col-10 col-md-8">
    <div class="pt-5 pb-3 d-lg-flex align-items-center gx-5">
      <div class="col-lg-4">
        <h2 class="h2 py-5 typo-space-line">Nuestro equipo</h2>
        <p class="text-muted light-300 text-justify">
          Para HIRA BRAND CONSULTING, la cultura de la excelencia está en Nuestro Equipo, lo que nos ha llevado a ofrecer soluciones legales con un alto énfasis en nuestros clientes, trabajando de la mano, brindando soluciones que nos distinguen de los demás.
          <br />
          <br />
          Reconocidos por nuestra calidad en cada servicio legal que brindamos, le atenderemos de forma personalizada y adecuando nuestra soluciones a cada situación jurídica, mostrándote cada lado de tu caso.
          <br />
          <br />
          Nuestro equipo de especialistas jurídicos cuentan con amplia experiencia en la práctica de áreas legales en México.
        </p>
      </div>

      <div class="col-lg-8 row">
        <div
          class="team-member col-md-6 align-items-center align-content-center"
          v-for="(member, index) in members"
          :key="index"
        >
          <div class="text-center">
            <img class="team-member-img rounded-circle p-4" :src="member.image" alt="Card image" />
          </div>
          <ul class="team-member-caption list-unstyled text-center pt-4 text-muted light-300">
            <li>{{ member.name }}</li>
            <li>{{ member.job }}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Member -->
</template>

<script>
export default {
  name: 'Team',
  computed: {
    members() {
      return [
        this.Object(
          'Lic. Sergio Adrian Hernandez Rodriguez',
          'Director',
          'https://randomuser.me/api/portraits/men/1.jpg'
        ),
        this.Object('Lic. Edgar Amigo De Adrian', 'Agente de diseño', 'https://randomuser.me/api/portraits/men/2.jpg'),
      ];
    },
  },

  methods: {
    /**
     * Values for input-floating
     *
     * @param {string|null} name - Name
     * @param {string|null} job - Job
     * @param {string|null} image - Image
     */
    Object(name = null, job = null, image = null) {
      const object = {};
      object.name = name || 'Contacto';
      object.job = job;
      object.image = image || 'Elit, sed do eiusmod tempor';
      return object;
    },
  },
};
</script>

<style scoped></style>
